import * as PageTypes from 'constants/constants';

import React, { ReactElement } from 'react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import { ComponentSelector } from '@components/ComponentSelector/ComponentSelector';
import { SkeletonSelector } from '@components/SkeletonSelector/SkeletonSelector';
import { getConfig } from '@utils/config.utils';
import tealium from '@services/Tealium.service';

import { getDeviceType } from '@utils/deviceType.utils';

import {
  fetchOverviewBySlug,
  mapGraphQLResponse,
  setInitialApplicationValues,
  setOverviewMetaData,
  getContentfulService,
  ContentHolder,
  attachDomaintoPath
} from '../src/imports';

import applicationData from '../application_data.json';

const Home = ({
  data,
  pageType,
  url,
  deviceType
}: any): ReactElement | null => {
  const config = getConfig();

  return (
    <>
      <Head>
        <link
          key="canonical"
          rel="canonical"
          href={process.browser ? window?.location?.href : attachDomaintoPath()}
          data-react-helmet
        />
      </Head>
      <ContentHolder
        data={{
          data,
          type: pageType,
          config,
          fullPath: url
        }}
        deviceType={deviceType}
        componentSelector={ComponentSelector}
        skeletonSelector={SkeletonSelector}
        tealium={tealium}
      />
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const queryParams = context.query;
  const deviceType = getDeviceType(context);
  const config = getConfig();
  const imageLambdaBase = process.env.NEXT_PUBLIC_IMAGELAMBDA;
  const content = getContentfulService();
  const application: any = applicationData.data.application;
  const pageType = PageTypes.OVERVIEW;
  const url = '';
  const { brandName, brandLogoUrl, fallbackImage } =
    await setInitialApplicationValues(application);

  const homeOverview = (
    await fetchOverviewBySlug(config, application.homeoverviewSlug)
  )[0];

  const homeOverviewWithUrl = { ...homeOverview, url: homeOverview.slug };

  const { metadata, schemaData } = setOverviewMetaData(
    homeOverviewWithUrl,
    brandName,
    brandLogoUrl,
    fallbackImage,
    url,
    pageType,
    application.homeoverviewSlug,
    application.homeoverviewref.title
  );

  if (application.numberOfLatestArticles)
    content.setLatest(application.numberOfLatestArticles);

  // mapOverview to have fields and sys obj
  const mapOverview = mapGraphQLResponse(homeOverview, 'overview');

  return {
    props: {
      data: mapOverview,
      pageType,
      url,
      metadata,
      schemaData,
      deviceType,
      queryParams,
      imageLambdaBase
    }
  };
};
